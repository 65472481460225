body,
html {
    font-family: 'Poppins', 'Viga', sans-serif;
    user-select: none;
    ondragstart: "return false";
    background-color: #002247;
}

@font-face {
    font-family: 'Viga';
    src: local('Viga'), url(./assets/fonts/Viga-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins'), url(./assets/fonts/Poppins-SemiBold.otf) format('opentype');
}